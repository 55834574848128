import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetIntegrationParams,
  getIntegration,
  IDisconnectIntegrationParams,
  disconnectIntegration,
  ISetIntegrationPausedParams,
  setIntegrationPaused,
} from '@src/requests/integrations/integrations';
import { IIntegration } from '@src/types/integrations/integrations';

const useGetIntegration = (params: IGetIntegrationParams) => {
  return useQuery<IIntegration, Error>(
    [QueryKey.integration, params.integrationId],
    () => getIntegration(params),
  );
};

const useDisconnectIntegration = () => {
  return useMutation<void, Error, IDisconnectIntegrationParams>(disconnectIntegration);
};

const useSetIntegrationPaused = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ISetIntegrationPausedParams>(
    (params) => setIntegrationPaused(params),
    {
      onSuccess: (data, variables) => {
        const { integrationId, isPaused } = variables;
        queryClient.setQueryData([QueryKey.integration, integrationId], (integration: any) => {
          return {
            ...integration,
            isEnabled: !isPaused,
          };
        });
      },
    },
  );
};

export {
  useGetIntegration,
  useDisconnectIntegration,
  useSetIntegrationPaused,
};
