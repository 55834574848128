import React from 'react';

import styles from '../../styles.module.scss';

const Loader = () => {
  return (
    <div className={ styles['channel-chat-skeleton'] }>
      <div className={ styles['skeleton-list'] }>
        <div className={ styles['list-line'] } />
        <div className={ styles['list-line'] } />
      </div>
      <div className={ styles['chat-list'] }>
        <div className={ styles['chat-list-inner'] }>
          <div className={ styles['list-circle'] } />
          <div className={ styles['list-line'] } />
        </div>
      </div>
      <div className={ styles['chat-right-list'] }>
        <div className={ styles['list-line'] } />
      </div>
      <div className={ styles['chat-list'] }>
        <div className={ styles['chat-list-inner'] }>
          <div className={ styles['list-circle'] } />
          <div className={ styles['list-line'] } />
        </div>
      </div>
      <div className={ styles['skeleton-list'] }>
        <div className={ styles['list-line'] } />
        <div className={ styles['list-line'] } />
      </div>
    </div>
  );
};
export default Loader;
