import React from 'react';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  LaborMetricIcon
} from '@src/components/utils/icomoon';

const LaborMetricsSidebar = () => {
  return (
    <ServiceSidebar.SubMenu
      expandedKey="labor_metrics_expanded"
      icon={ <LaborMetricIcon /> }
      title="Labor Metric"
    >
      {/* Labor Metric content here */}
    </ServiceSidebar.SubMenu>
  );
};

export default LaborMetricsSidebar;
