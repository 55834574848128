import React from 'react';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';

import DetailsV2 from './details_v2';
import MetricsServiceValuesRouter from './router';
import Sidemenu from './sidemenu';
import SidemenuV2 from './sidemenu_v2';

interface IMetricsServiceDetailsPageProps {
  businessId: number,
  isIndustryMetrics: boolean,
}

const MetricsServiceLandingPage = ({
  businessId,
  isIndustryMetrics,
}: IMetricsServiceDetailsPageProps): JSX.Element => {

  const featureQuery = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.METRIC_SERVICE_REVAMP,
  );
    if (featureQuery.data){
      return (
        <>
          <div id="sidemenu-region">
            <SidemenuV2 />
          </div>
          <div id="detail-region">
            <DetailsV2 />
          </div>                   
        </>
      );
    }

return (
  <>
    <div id="sidemenu-region">
      <Sidemenu />
    </div>
    <div id="detail-region">
      <MetricsServiceValuesRouter
        isIndustryMetrics={ isIndustryMetrics }
      />
    </div>
  </>
);
};

export default MetricsServiceLandingPage;
