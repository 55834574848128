import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useMetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui/service_sidebar';

import BusinessMetricsSidebar from './business_metrics_sidebar';
import LaborMetricsSidebar from './labor_metrics_sidebar';

const MetricsServiceSideMenuV2 = () => {
  const business = useBusinessContext();
  const service = useMetricsServiceContext();
  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.METRIC_REPORT_MAPPING_TEMPLATE,
  );
  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          featureQuery={ featureQuery.data }
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.METRICS_SERVICE }
        />
    ) }
    >
      <BusinessMetricsSidebar />
      <LaborMetricsSidebar />
    </ServiceSidebar>
  );
};

export default MetricsServiceSideMenuV2;
