import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useMetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import { Button } from '@src/components/ui_v2/buttons';
import {
  BusinessMetricIcon,
  PlusIcon,
} from '@src/components/utils/icomoon';

import { useImportMetricsServiceModal } from '../modal/import_metric_service_modal';

import styles from './styles.module.scss';

const BusinessMetricsSidebar = () => {
  const business = useBusinessContext();
  const service = useMetricsServiceContext();
  const importMetricsServiceModal = useImportMetricsServiceModal();
  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.METRIC_REPORT_MAPPING_TEMPLATE,
  );
  return (
    <>
      <ServiceSidebar.SubMenu
        expandedKey="business_metrics_expanded"
        icon={ <BusinessMetricIcon /> }
        title="Business Metric"
      >
        {featureQuery.data && (
        <Button className={ styles['custom-button'] } variant="link" onClick={ importMetricsServiceModal.open }>
          <PlusIcon fontVariant="bold" />
          {' '}
          Import Data
        </Button>
        )}
      </ServiceSidebar.SubMenu>
      <importMetricsServiceModal.Component
        businessId={ business.id }
        businessName={ `${business.name}` }
        serviceEmail={ `${service?.uploadEmail}` }
        standardIndustryId={ business.standardCategoryId }
        { ...importMetricsServiceModal.props }
      />
    </>
  );
};

export default BusinessMetricsSidebar;
