import React, { useMemo } from 'react';

import { useGetFinancialInstitutionConnection } from '@src/hooks/queries/financial_institution_connection';
import { useURLParams } from '@src/hooks/url_params';
import { TID } from '@src/types/common';
import { TFinancialInstitutionConnectionSortColumn } from '@src/types/financial_institution_connection';
import { ISortingParams } from '@src/types/sorting';

import Spinner from '@src/components/ui/spinner';

import useConnectFinancialInstitutionModal from './connect_financial_institution/modal';
import { IFinancialInstitutionConnectionFilter } from './filter';
import BlankList from './list_item/blank_list';
import List from './list_item/list';

const DefaultSorting: ISortingParams<TFinancialInstitutionConnectionSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

interface IFinancialInstitutionConnectionDetailsProps {
  businessId?: TID,
}

const FinancialInstitutionConnectionDetails = ({
  businessId,
}: IFinancialInstitutionConnectionDetailsProps): JSX.Element => {
  const { filter } = useURLParams<
  IFinancialInstitutionConnectionFilter,
  TFinancialInstitutionConnectionSortColumn
    >({
      section:        window.Docyt.Common.Constants.FINANCIAL_INSTITUTION_CONNECTION_TYPE,
      defaultSorting: DefaultSorting,
    });

  const query = useGetFinancialInstitutionConnection({ filter: filter.data });

  const financialInstitutionConnections = useMemo(() => {
    return query.data?.financialInstitutionConnections || [];
  }, [query.data]);

  const noFilteredCount = query.data?.meta?.noFilteredCount || 0;

  const connectFinancialInstitutionModal = useConnectFinancialInstitutionModal();

  return (
    <div>
      {query.isLoading && <Spinner /> }
      <connectFinancialInstitutionModal.Component
        { ...connectFinancialInstitutionModal.props }
        financialInstitutionConnections={ financialInstitutionConnections }
      />
      {
        noFilteredCount === 0 && !query.isLoading
          ? (
            <BlankList
              onConnectFinancialInstitution={ connectFinancialInstitutionModal.open }
            />
          ) : (
            <List
              businessId={ businessId }
              filter={ filter }
              financialInstitutionConnections={ financialInstitutionConnections }
              onConnectFinancialInstitution={ connectFinancialInstitutionModal.open }
            />
          )
      }
    </div>
  );
};

export default FinancialInstitutionConnectionDetails;
