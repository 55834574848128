/* eslint-disable import/order */
import React, { useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { makeUseModal } from '@src/hooks/modal';
import { usePutVendorW9Info } from '@src/hooks/queries/vendor_w9_info';
import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';

import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import {
  DocytLogoIcon,
  NotAChargebackIcon,
} from '@src/components/utils/icomoon';

import { IW9InfoFormValues } from './schema';
import W9InfoDetails from './w9_info_details';
import W9InfoDetailsForm from './w9_info_details_form';
import UploadView from './w9_info_upload_view';
import W9InfoModalAction from '../../actions/w9_info_modal_action';

import styles from './styles.module.scss';

const formId = 'w9_info_form';

interface IW9InfoUploadModalProps {
  businessId: TID,
  id: TID,
  isOpen: boolean,
  vendor: IW9InfoResponse,
  onCancel: () => void,
  onDone: () => void,
  modalMode?: string,
}

const W9InfoUploadModal = ({
  businessId,
  isOpen,
  onDone,
  onCancel,
  id,
  vendor,
  modalMode,
}: IW9InfoUploadModalProps): JSX.Element => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const createVendorW9Info = usePutVendorW9Info();

  const { mutate: createW9Info } = createVendorW9Info;

  const handleError = useCallback((response) => {
    setErrorMsg(response?.response?.data?.errors[0]);
  }, []);

  const handleSubmitW9Info = useCallback((data: IW9InfoFormValues) => {
    setIsEditing(false);
    createW9Info(
      {
        ...data,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.vendorW9Info);
          onDone();
        },
        onError: handleError,
      },
    );
  }, [createW9Info, onDone, queryClient, handleError]);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleEditClose = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    onCancel();
  }, [onCancel]);

  return (
    <>
      {(createVendorW9Info.isLoading) && <Spinner />}
      {errorMsg && <ErrorNotification message={ errorMsg } title="Something went wrong" onHidden={ () => setErrorMsg(undefined) } />}
      <Modal
        className={ styles['w9-info-upload-modal'] }
        closeIcon={ <NotAChargebackIcon color="blue" fontSize={ 24 } inColor="grey-1050" /> }
        show={ isOpen }
        title={ (
          <div className={ styles['header-container'] }>
            <DocytLogoIcon className={ styles['icon-header'] } fontSize={ 24 } />
            <p className={ styles['modal-header'] }>
              W9 Info
            </p>
            {vendor?.w9FileUrl && (
            <W9InfoModalAction
              businessId={ businessId }
              id={ id }
              vendor={ vendor }
            />
            ) }
          </div>
) }
        onCancel={ handleCancel }
      >
        <Modal.Body>
          <div className="display-flex width-100-percent">
            <UploadView
              businessId={ businessId }
              modalMode={ modalMode }
              vendorId={ id }
              w9Info={ vendor }
            />
            {modalMode !== 'view' && (
              !isEditing && modalMode === 'edit' ? (
                <W9InfoDetails
                  handleEditClick={ handleEditClick }
                  w9Info={ vendor }
                />
              ) : (
                <W9InfoDetailsForm
                  businessId={ businessId }
                  formId={ formId }
                  handleEditClose={ handleEditClose }
                  id={ id }
                  isEditing={ isEditing }
                  w9Info={ vendor }
                  onSubmit={ handleSubmitW9Info }
                />
              )
            )}

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const useW9InfoUploadModal = makeUseModal(W9InfoUploadModal);

export {
  IW9InfoUploadModalProps,
  useW9InfoUploadModal,
};
