import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IHeaderRegionTabProps extends Omit<React.ComponentPropsWithoutRef<'a'>, 'href'> {
  active?: boolean,
  href: string,
  title: string,
  onTabSelected?: () => void
}

const HeaderRegionTab = ({
  active,
  href,
  title,
  className,
  onTabSelected,
  ...props
}: IHeaderRegionTabProps): JSX.Element => {
  const classes = classNames(styles.tab, { [styles.active]: active }, className);

  return (
    <a
      className={ classes }
      href={ href }
      onClick={ onTabSelected }
      { ...props }
    >
      {title}
    </a>
  );
};

export default React.memo(HeaderRegionTab);
