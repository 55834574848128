import React, { memo } from 'react';

import ChatCentralChatIcon from '@src/components/utils/img_icons/chat_central_chat_icon';

import styles from '../../styles.module.scss';

const ChannelListHeader = () => {
  return (
    <div className={ styles['channel-list-header'] }>
      <ChatCentralChatIcon height="24px" width="24px" />
      <h2>Chats</h2>
    </div>
  );
};

export default memo(ChannelListHeader);
