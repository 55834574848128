import React from 'react';

import {
  IAccountsPayableServiceDocument,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';

import CollectionTable from '@src/components/ui_v2/collection_table';

import InvoiceQueueFilter from '../filter';
import { IInvoiceQueueCollection } from '../hooks';

interface IInvoiceQueueTableProps {
  collection: IInvoiceQueueCollection
}

const InvoiceQueueTable = ({ collection }: IInvoiceQueueTableProps) => {
  return (
    <CollectionTable<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      filter={ <InvoiceQueueFilter /> }
      height="750px"
      query={ collection.query }
      records={ collection.records }
      sorting={ collection.sorting }
    >
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="star_flag"
        title="Star Flag"
        value={ () => {} }
      />
    </CollectionTable>
  );
};

export default React.memo(InvoiceQueueTable);
